.image-container {
  position: relative;
  width: 11px;
  height: 10px;
}

.hover-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  /* Initially hidden */
  transition: opacity 0.3s ease-in-out;
  /* Smooth transition effect */
}

.original-image {
  position: absolute;
  top: 0;
  left: 0;
  /* Show hover image when original image is hovered */
}


.original-image:hover+.hover-image {
  opacity: 1;
  /* Show hover image when original image is hovered */
}